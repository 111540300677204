<template>
<div class="comp-container">
    test_01
</div>
</template>
<script>
export default {
//props: {
//},
data() {
return {

}
},
created() {},
mounted() {},
//watch: {},
computed: {},
methods: {

},
components: {
},
}
</script>
<style scoped>
.comp-container{
    background-color: grey;
    padding: 20px;
    text-align: center;
    font-size:8px;
}
</style>